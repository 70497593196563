import React from "react";
import { NavLink as NavLinkRRD, Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

class PageNotFound404 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        
        }

    }

    render() {
        return (
          <div>
              
          </div>
        );
    };
}

const mapStateToProps = (state) => ({
})

export default withRouter(connect(mapStateToProps, {
})(PageNotFound404));