import React from "react";
import { connect } from 'react-redux';

import LabelInput from 'components/Helpers/LabelInput';
// import { SetValue } from 'store/actions/helpers/displayAction.js';

import { ArrangeDate } from 'store/actions/helpers/displayAction';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Badge,
    Form,
    Input,
    Row,
    Col,
} from "reactstrap";
import { FaTimesCircle } from 'react-icons/fa';

const JobPostForm = (props) => {
    var me = props.Profile.values._id;


    // qualified qualified
    // exam exam-passer
    // final top
    // selected selected

    //props.jobValues.applicants

    var list = props.jobValues.applicants;
    var message = "";
    var notSelected = false;
    var jpStatus = props.jobValues.status || "";

    if (props.jobValues.stage == "qualified") {
        for (let x = 0; x < list.length; x++) {
            if (list[x].applicant == me && list[x].status == "qualified") {
                message = "You are qualified to take the Exam";
            }
        }
    } else if (props.jobValues.stage == "exam") {
        for (let x = 0; x < list.length; x++) {
            if (list[x].applicant == me && list[x].status == "exam-passer") {
                message = "You Passed the NEDA Exam";
            }
        }
    } else if (props.jobValues.stage == "final") {
        for (let x = 0; x < list.length; x++) {
            if (list[x].applicant == me && list[x].status == "top") {
                message = "You are selected as one of the TOP 5";
            }
        }
    } else if (props.jobValues.stage == "selected") {
        for (let x = 0; x < list.length; x++) {
            if (list[x].applicant == me && list[x].status == "selected") {
                message = "You've been selected for the Vacant Position";
            }
        }

        if (message == "") {
            notSelected = true;
            jpStatus = "Done Selection"
        }
    }


    return (
        <>
            <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Job Detail</h3>
                            </Col>
                        </Row>

                    </CardHeader>
                    <CardBody>
                        <h4 className="mb-0"
                            style={{
                                padding: "5px",
                                borderRadius: "2px",
                                fontWeight: "bolder"
                            }}
                        >
                            Status: &nbsp;{(jpStatus).toUpperCase()}
                        </h4>
                        {
                            (message != "")
                                ? <h4 className="mb-0"
                                    style={{
                                        padding: "5px",
                                        borderRadius: "2px",
                                        color: "#18d418",
                                    }}
                                >
                                    Congratulations! &nbsp;<i>{message}</i>
                                </h4>
                                : ""
                        }<br />
                        <Form>
                            <div className="pl-lg-12">
                                <Row>
                                    <Col lg="6">
                                        <LabelInput
                                            className="uneditable"
                                            label={"Position Title"}
                                            prop={"position"}
                                            value={props.jobValues.position}
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => {
                                                // props.SetValue(e, SET_JOB_POST_VALUE);
                                            }}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <LabelInput
                                            className="uneditable"
                                            label={"Plantilla No."}
                                            prop={"plantilla"}
                                            value={props.jobValues.plantilla}
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => {
                                                // props.SetValue(e, SET_JOB_POST_VALUE);
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg="6">
                                        <LabelInput
                                            className="uneditable"
                                            label={"Place of Assignment"}
                                            prop={"poa"}
                                            value={props.jobValues.poa}
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => {
                                                // props.SetValue(e, SET_JOB_POST_VALUE);
                                            }}
                                        />
                                    </Col>
                                    <Col lg="3">
                                        <LabelInput
                                            className="uneditable"
                                            label={"Salary Grade"}
                                            prop={"sg.grade"}
                                            value={props.jobValues.sg.grade}
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => {
                                                // props.SetValue(e, SET_JOB_POST_VALUE);
                                            }}
                                        />
                                    </Col>
                                    <Col lg="3">
                                        <LabelInput
                                            className="uneditable"
                                            label={"Amount"}
                                            prop={"sg.salary"}
                                            value={props.jobValues.sg.salary}
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => {
                                                // props.SetValue(e, SET_JOB_POST_VALUE);
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg="6">
                                        <LabelInput
                                            className="uneditable"
                                            label={"Date Posted"}
                                            prop={"date.post"}
                                            value={(typeof props.jobValues.date.post != "object")?props.ArrangeDate(props.jobValues.date.post, false):""}
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => {
                                                // props.SetValue(e, SET_JOB_POST_VALUE);
                                            }}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <LabelInput
                                            className="uneditable"
                                            label={"Date Close"}
                                            prop={"date.close"}
                                            value={(typeof props.jobValues.date.post != "object")?props.ArrangeDate(props.jobValues.date.close, false):""}
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => {
                                                // props.SetValue(e, SET_JOB_POST_VALUE);
                                            }}
                                        />
                                    </Col>
                                </Row>

                            </div>
                            <hr className="my-4" />
                            <h6 className="heading-small text-muted mb-4">
                            </h6>
                            <Row>
                                <Col lg="12">
                                    <div className="educ-attainments-div form-group label-input">
                                        <label class="form-control-label">Eligibility</label>
                                        <p>
                                            {props.jobValues.other.eligibility}
                                        </p>
                                    </div>

                                    <div className="educ-attainments-div form-group label-input">
                                        <label class="form-control-label">Educational Attainment</label>
                                        <p>
                                            {props.jobValues.other.education.join(", ")}
                                        </p>
                                    </div>
                                    <div className="educ-attainments-div form-group label-input">
                                        <label class="form-control-label">Work Experience</label>
                                        <p>
                                            {props.jobValues.other.wEx}
                                        </p>
                                    </div>

                                    <div className="educ-attainments-div form-group label-input">
                                        <label class="form-control-label">Training</label>
                                        <p>
                                            {props.jobValues.other.training}
                                        </p>
                                    </div>

                                    <div className="educ-attainments-div form-group label-input">
                                        <label class="form-control-label">Competency</label>
                                        <p>
                                            {props.jobValues.other.competency}
                                        </p>
                                    </div>
                                    <div className="educ-attainments-div form-group label-input">
                                        <label class="form-control-label">Instructions/Remarks</label>
                                        <p>
                                            {props.jobValues.other.remarks}
                                        </p>
                                    </div>
                                    <div className="educ-attainments-div form-group label-input">
                                        <p>
                                        </p>
                                    </div>

                                </Col>
                            </Row>

                        </Form>

                    </CardBody>
                </Card>
            </Col>

        </>
    );
};

const mapStateToProps = (state) => ({
    JobPost: state.JobPost,
    jobValues: state.JobPost.values,
    Profile: state.Profile,
})

export default connect(mapStateToProps, {
    // SetValue,
    ArrangeDate,
})(JobPostForm);
