import React, { Fragment } from "react";
import { NavLink as NavLinkRRD, Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

// reactstrap components
import {
   Button,
   Card,
   CardHeader,
   CardBody,
   Container,
   CardFooter,
   Row,
   Col,
} from "reactstrap";

import InfoModal from "components/Helpers/InfoModal.js";
import JobPostForm from "./forms/JobPostForm.js";
import DataTable from "components/Helpers/DataTable.js";
import UserHeader from "components/Headers/UserHeader.js";

import { SET_JOB_POSTS, SET_SEARCHED_JOB_POSTS, SET_JOB_POSTS_RELATED, SET_SEARCHED_JOB_POSTS_RELATED } from "./redux/types";
import { SetJobPostDetail, Apply, Save, WithdrawApplication, } from "./redux/actions";

import { GetList, ArrangeDate, ArrangeAmount, } from "store/actions/helpers/displayAction";
import { GetAsiaManilaDate } from "store/actions/helpers/dateAction";


class JobPost extends React.Component {

   constructor(props) {
      super(props);

      this.state = {
         modal: false,
         modalApplyInc: false,
         modalApplyConf: false,
         modalWithdrawConf: false,
         applyMessage: "",
         currentDate: new Date(),
      }

      props.GetList("job-post/get", SET_JOB_POSTS, 1, 10, { status: "posted" }, { "date.post": -1 });
      props.GetList("job-post/get", SET_JOB_POSTS_RELATED, 1, 10, { status: "posted" }, { "date.post": -1 });
      props.GetAsiaManilaDate()
         .then(data => {
            this.setState({
               currentDate: data
            })
         })
      this.toggle = this.toggle.bind(this);
      this.toggleApplyIncompleteModal = this.toggleApplyIncompleteModal.bind(this);
      this.toggleApplyConfModal = this.toggleApplyConfModal.bind(this);
      this.toggleWithdrawConfModal = this.toggleWithdrawConfModal.bind(this);
      this.isJobApplied = this.isJobApplied.bind(this);
      this.isJobSaved = this.isJobSaved.bind(this);
   }


   toggle(type) {
      this.setState({
         ...this.state,
         modal: !this.state.modal,
      });
   }

   toggleApplyIncompleteModal(message = "") {
      this.setState({
         ...this.state,
         modalApplyInc: !this.state.modalApplyInc,
         applyMessage: (typeof message == "String") ? message : "",
      });
   }

   toggleApplyConfModal() {
      this.setState({
         ...this.state,
         modalApplyConf: !this.state.modalApplyConf,
      });
   }

   toggleWithdrawConfModal() {
      this.setState({
         ...this.state,
         modalWithdrawConf: !this.state.modalWithdrawConf,
      });
   }

   isJobApplied(jobID) {
      var appliedJobs = this.props.Profile.values.appliedJobs;

      for (let x = 0; x < appliedJobs.length; x++) {
         if (jobID == appliedJobs[x].job) {
            return true;
         }
      }

      return false;
   }

   isJobSaved(jobID) {
      var savedJobs = this.props.Profile.values.savedJobs;

      for (let x = 0; x < savedJobs.length; x++) {
         if (jobID == savedJobs[x].job) {
            return true;
         }
      }

      return false;
   }

   render() {
      var files = { pds: false, resume: false, appLetter: false }
      var { personalInfo, education, eligibility, workexperience, voluntary, trainings, document } = this.props.Profile.isComplete;

      this.props.Profile.values.files.map((file, i) => {

         if (file.type == "pds" && file.status == "current") {
            files.pds = true;
         } else if (file.type == "resume" && file.status == "current") {
            files.resume = true;
         } else if (file.type == "app-letter" && file.status == "current") {
            files.appLetter = true;
         }
      });

      var href = window.location.pathname.split("/")[1];

      return (
         <>
            <InfoModal
               size={"20%"}
               modal={this.state.modalApplyConf}
               toggle={this.toggleApplyConfModal}
               title={""}
               form={<Fragment>
                  <div style={{ textAlign: "center" }}>
                     <p class="text-center"><i>You're About to Apply for the position:</i></p>
                     <p class="text-center"><strong><bold>{this.props.JobPost.values.position}</bold></strong></p>
                     <Button onClick={() => {
                        this.props.Apply();
                        this.toggleApplyConfModal();
                     }} className="my-4 mt-2" color="primary" type="button" size="md">
                        Confirm
                     </Button>
                  </div>
               </Fragment>}
               buttons={[]}
            />

            <InfoModal
               size={"20%"}
               modal={this.state.modalWithdrawConf}
               toggle={this.toggleWithdrawConfModal}
               title={""}
               form={<Fragment>
                  <div style={{ textAlign: "center" }}>
                     <p class="text-center"><i>Your About to Withdraw you application for the position:</i></p>
                     <p class="text-center"><strong><bold>{this.props.JobPost.values.position}</bold></strong></p>
                     <Button onClick={() => {
                        this.props.WithdrawApplication(this.props.JobPost.values._id);
                        this.toggleWithdrawConfModal();
                     }} className="my-4 mt-2" color="primary" type="button" size="md">
                        Confirm
                     </Button>
                  </div>
               </Fragment>}
               buttons={[]}
            />

            <InfoModal
               size={"30%"}
               modal={this.state.modalApplyInc}
               toggle={this.toggleApplyIncompleteModal}
               title={""}
               form={<Fragment>
                  <p class="text-center"><strong>Incomplete Profile Info</strong></p>
                  {/* <p class="text-center"><em>{this.state.applyMessage}</em></p> */}
                  <p class="text-center"><Link
                     className="text-center"
                     to="/my-profile/personal_info"
                     style={{ color: "#525f7f" }}
                  >
                     Click Here to Update
                  </Link></p>
               </Fragment>}
               buttons={[]}
            />

            <InfoModal
               size={"70%"}
               modal={this.state.modal}
               toggle={this.toggle}
               title={"Job"}
               form={<JobPostForm data={this.state.data} />}
               buttons={[
                  (new Date(this.props.JobPost.values.date.close).setHours(0, 0, 0, 0) >= new Date(this.state.currentDate).setHours(0, 0, 0, 0))
                     ? (!this.isJobApplied(this.props.JobPost.values._id))
                        ? {
                           type: "Apply", disable: false, callback: () => {
                              if ((new Date(this.props.JobPost.values.date.close).setHours(0, 0, 0, 0) >= new Date(this.state.currentDate).setHours(0, 0, 0, 0))) {
                                 if (personalInfo && education && eligibility && workexperience && trainings && document) {
                                    // if (files.pds && files.resume && files.appLetter) {
                                    this.toggleApplyConfModal();
                                 } else {
                                    // var lacking = [];

                                    // if (!files.pds) lacking.push("PDS");
                                    // if (!files.resume) lacking.push("RESUME");
                                    // if (!files.appLetter) lacking.push("Application Letter");

                                    // this.toggleApplyIncompleteModal(lacking.join(" | "));
                                    this.toggleApplyIncompleteModal();
                                 }

                              }
                           }
                        }
                        : {
                           type: "Withdraw", disable: false, callback: () => {
                              this.toggleWithdrawConfModal();
                           }
                        }
                     : {
                        type: "Closed", disable: true, callback: () => { }
                     },
                  {
                     type: "Save", disable: false,
                     callback: () => {
                        this.props.Save();
                     }
                  }]}
            />


            <Container fluid className={"jp-container " + this.props.className}>
               <Row className="posting-area" style={{ paddingTop: "20px" }}>
                  <Col className="mb-5 mb-xl-0" xl="12">
                     <Card className="shadow">
                        {
                           (this.props.JobPost.toDisplay.length > 0)
                              ? <CardHeader className="bg-transparent pa-head">
                                 <Row className="align-items-center">
                                    <div className="col">
                                       <h3 className="mb-0">Vacant Jobs</h3>
                                    </div>
                                 </Row>
                                 <CardBody className="cb-tb">
                                    <DataTable
                                       id="relevant-jobs-table"
                                       title={""}
                                       filter={{ status: "posted" }}
                                       api={{ get: "job-post/get", search: "job-post/get" }}
                                       dataBank={this.props.JobPost}
                                       reducers={{ get: SET_JOB_POSTS, search: SET_SEARCHED_JOB_POSTS }}
                                       search={{
                                          options: [{ value: "position", text: "Job Title" }, { value: "plantilla", text: "Plantilla" }],
                                          select: [], suggest: false,
                                       }}
                                       table={{
                                          head: () => {
                                             return (
                                                <tr>
                                                </tr>
                                             )
                                          },
                                          body: (jobPost, i) => {
                                             return (
                                                <tr className="clickable" data-id={jobPost._id} onClick={async (e) => {
                                                }}>
                                                   <td scope="col">
                                                      <div className="job-post">
                                                         <Card className="shadow">
                                                            <CardHeader className="bg-transparent pr-2 pb-2 pt-2 mt-0" style={{ display: "grid" }}>

                                                               {
                                                                  (this.isJobSaved(jobPost._id))
                                                                     // (true)
                                                                     ? <span><em className="jp-badge">Saved</em></span>
                                                                     : ""
                                                               }

                                                               {
                                                                  (this.isJobApplied(jobPost._id))
                                                                     // (true)
                                                                     ? <span><em className="jp-badge">Applied</em></span>
                                                                     : ""
                                                               }

                                                            </CardHeader>
                                                            <CardBody className="pr-3 pl-4 pt-3 pb-2">
                                                               <div>
                                                                  <span>Job Title: </span><br />
                                                                  <p>{jobPost.position.toUpperCase()}</p>
                                                               </div>
                                                               <div>
                                                                  <span>Plantilla No.: </span><br />
                                                                  <p>{jobPost.plantilla.toUpperCase()}</p>
                                                               </div>
                                                               <div>
                                                                  <span>Salary: </span><br />
                                                                  <p>{"SG-" + jobPost.sg.grade + " | " + this.props.ArrangeAmount(jobPost.sg.salary)} </p>
                                                               </div>
                                                               <div>
                                                                  <span>Place of Assignment: </span><br />
                                                                  <p> {jobPost.poa.toUpperCase()}</p>
                                                               </div>
                                                               <Button onClick={async () => {
                                                                  await this.props.SetJobPostDetail(jobPost._id);
                                                                  this.toggle();
                                                               }} className="my-1 mt-3" color="primary" type="button">
                                                                  View Details
                                                                  {/* <i className="fas fa-greater-than" /> */}
                                                               </Button>
                                                            </CardBody>
                                                            <CardFooter className="pl-2 pt-2" style={{ display: "grid" }}>
                                                               <span>Date Posted: <em>{this.props.ArrangeDate(jobPost.date.post, false)}</em></span>

                                                               <span>Deadline of Submission: <em>{this.props.ArrangeDate(jobPost.date.close, false)}</em></span>
                                                            </CardFooter>
                                                         </Card>
                                                      </div>

                                                   </td>

                                                </tr>
                                             )
                                          }
                                       }}
                                    />
                                 </CardBody>
                              </CardHeader>
                              : <CardHeader className="bg-transparent pa-head">
                                 <Row className="align-items-center">
                                    <div className="col">
                                       <h3 className="mb-0" style={{textAlign: "center"}}>No Vacant Jobs</h3>
                                    </div>
                                 </Row>
                              </CardHeader>
                        }
                     </Card>
                  </Col>

                  {/* <Col className="mb-5 mb-xl-0" xl="6">
                     <Card className="shadow">
                        <CardHeader className="bg-transparent pa-head">
                           <Row className="align-items-center">
                              <div className="col">
                                 <h3 className="mb-0">Recommended for you</h3>
                              </div>
                           </Row>
                           <CardBody className="cb-tb">
                              <DataTable
                                 id="relevant-jobs-table"
                                 title={""}
                                 filter={{ status: "posted" }}
                                 api={{ get: "job-post/get", search: "job-post/get" }}
                                 dataBank={this.props.JobPost.related}
                                 // dataBank={this.props.JobPost.related}
                                 reducers={{ get: SET_JOB_POSTS_RELATED, search: SET_SEARCHED_JOB_POSTS_RELATED }}
                                 search={{
                                    options: [{ value: "position", text: "Job Title" }, { value: "plantilla", text: "Plantilla" }],
                                    select: [], suggest: false,
                                 }}
                                 table={{
                                    head: () => {
                                       return (
                                          <tr>
                                          </tr>
                                       )
                                    },
                                    body: (jobPost, i) => {
                                       return (
                                          <tr className="clickable" data-id={jobPost._id} onClick={async (e) => {
                                          }}>
                                             <td scope="col">
                                                <div className="job-post">
                                                   <Card className="shadow">
                                                      <CardHeader className="bg-transparent pr-2 pb-2 mt-0" style={{ display: "grid" }}>

                                                         {
                                                            (this.isJobSaved(jobPost._id))
                                                               // (true)
                                                               ? <span><em style={{ float: "right", color: "#5ab3ff", fontSize: "10px" }}>Saved</em></span>
                                                               : ""
                                                         }

                                                         {
                                                            (this.isJobApplied(jobPost._id))
                                                               // (true)
                                                               ? <span><em style={{ float: "right", color: "#5ab3ff", fontSize: "10px" }}>Applied</em></span>
                                                               : ""
                                                         }

                                                      </CardHeader>
                                                      <CardBody className="pr-3 pl-4 pt-3 pb-2">
                                                         <div>
                                                            <span>Job Title: </span><br />
                                                            <p>{jobPost.position.toUpperCase()}</p>
                                                         </div>
                                                         <div>
                                                            <span>Plantilla No.: </span><br />
                                                            <p>{jobPost.plantilla.toUpperCase()}</p>
                                                         </div>
                                                         <div>
                                                            <span>Salary: </span><br />
                                                            <p>{"SG-" + jobPost.sg.grade + " | " + this.props.ArrangeAmount(jobPost.sg.salary)} </p>
                                                         </div>
                                                         <div>
                                                            <span>Place of Assignment: </span><br />
                                                            <p> {jobPost.poa.toUpperCase()}</p>
                                                         </div>
                                                         <Button onClick={async () => {
                                                            await this.props.SetJobPostDetail(jobPost._id);
                                                            this.toggle();
                                                         }} className="my-1 mt-3" color="primary" type="button">
                                                            View Details
                                                         </Button>
                                                      </CardBody>
                                                      <CardFooter className="pl-2 pt-2" style={{ display: "grid" }}>
                                                         <span>Date Posted: <em>{this.props.ArrangeDate(jobPost.date.post, false)}</em></span>

                                                         <span>Deadline of Submission: <em>{this.props.ArrangeDate(jobPost.date.close, false)}</em></span>
                                                      </CardFooter>
                                                   </Card>
                                                </div>

                                             </td>

                                          </tr>
                                       )
                                    }
                                 }}
                              />
                           </CardBody>
                        </CardHeader>
                     </Card>
                  </Col> */}

               </Row>

            </Container>
         </>
      );
   };
}

const mapStateToProps = (state) => ({
   JobPost: state.JobPost,
   Profile: state.Profile,
})

export default withRouter(connect(mapStateToProps, {
   GetList,
   ArrangeDate,
   ArrangeAmount,
   GetAsiaManilaDate,
   SetJobPostDetail,
   Apply,
   Save,
   WithdrawApplication,
})(JobPost));