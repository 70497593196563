import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { Toaster } from 'react-hot-toast';

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import PageNotFound404 from 'views/PageNotFound404';

import routes from "routes.js";

const Admin = (props) => {
   const mainContent = React.useRef(null);
   const location = useLocation();

   React.useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainContent.current.scrollTop = 0;
   }, [location]);

   const getRoutes = (routes) => {
      console.log("************************!!!!!");
      console.log(routes);
      return routes.map((prop, key) => {
         if (prop.layout === "/admin") {
            return (
               <Route
                  path={prop.path}
                  component={prop.component}
                  key={key}
               />
            );
         } else {
            return null;
         }
      });
   };

   const getBrandText = (path) => {
      for (let i = 0; i < routes.length; i++) {
         if (
            props.location.pathname.indexOf(routes[i].path) !==
            -1
         ) {
            return routes[i].name;
         }
      }
      return "Brand";
   };

   return (
      <>
         {
            (localStorage.getItem("nro13a-dvctype") == "mobile")
               ? <Sidebar
                  {...props}
                  routes={routes}
                  logo={{
                     innerLink: "/index",
                     imgSrc: require("../assets/img/LOGO2.png").default,
                     imgAlt: "...",
                  }}
               /> : ""
         }

         {
            (localStorage.getItem("nro13a-dvctype") == "mobile")
               ? <div className="main-content" ref={mainContent}>
                  <Toaster
                     position="bottom-right"
                     reverseOrder={false}
                     gutter={8}
                     containerClassName=""
                     containerStyle={{}}
                     toastOptions={{
                        // Define default options
                        className: '',
                        duration: 5000,
                        style: {
                           background: '#363636',
                           color: '#fff',
                        },
                        // Default options for specific types
                        success: {
                           duration: 3000,
                           theme: {
                              primary: 'green',
                              secondary: 'black',
                           },
                        },
                     }}
                  />
                  <AdminNavbar
                     {...props}
                     brandText={getBrandText(props.location.pathname)}
                  />
                  <Switch>
                     {getRoutes(routes)}
                     {/* <Route render={(props) => <div>Page Not Fouond</div>} /> */}
                     {/* <Route component={PageNotFound404} /> */}

                  </Switch>
                  <Container fluid>
                     <AdminFooter />
                  </Container>
               </div>
               : <div id="space">
                  <div className="side-space ss-left"></div>
                  <div className="main-space">
                     <Toaster
                        position="bottom-right"
                        reverseOrder={false}
                        gutter={8}
                        containerClassName=""
                        containerStyle={{}}
                        toastOptions={{
                           // Define default options
                           className: '',
                           duration: 5000,
                           style: {
                              background: '#363636',
                              color: '#fff',
                           },
                           // Default options for specific types
                           success: {
                              duration: 3000,
                              theme: {
                                 primary: 'green',
                                 secondary: 'black',
                              },
                           },
                        }}
                     />
                     <div className="main-content" ref={mainContent}>
                        <AdminNavbar
                           {...props}
                           brandText={getBrandText(props.location.pathname)}
                        />
                        <Switch>
                           {getRoutes(routes)}
                           {/* <Redirect from="*" to="/404" /> */}
                           {/* <Route render={(props) => <div>Page Not Fouond</div>} /> */}
                        </Switch>
                        <Container fluid>
                           <AdminFooter />
                        </Container>
                     </div>
                  </div>
                  <div className="side-space ss-right"></div>
               </div>

         }


      </>
   );
};

export default Admin;
