/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { SERVER_URI } from 'config';

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  NavbarBrand,
  Container,
  Media,
} from "reactstrap";

import NotificationArea from 'components/Headers/NotificationArea';

import { ArrangeName } from 'store/actions/helpers/displayAction';

const AdminNavbar = (props) => {

  var [openTooltip, toggleTooltip] = useState(false);
  var [tooltipShown, toggleTooltipShown] = useState(false);

  if (!tooltipShown) {
    var timer = setTimeout(() => {

      toggleTooltip(true);
      toggleTooltipShown(true);
      var timer2 = setTimeout(() => {

        toggleTooltip(false);
        clearTimeout(timer2);
      }, 10000);


      clearTimeout(timer);
    }, 1000);

  }

  var ls = JSON.parse(localStorage.getItem("nro13a-info"));
  var profilePic = localStorage.getItem("nro13a-pp");
  var href = window.location.pathname.split("/")[1];
  var logo = ((href == "index" || href == "my-profile"))
    ? require("../../assets/img/LOGO.png").default
    : require("../../assets/img/LOGO.png").default;

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main"
        style={(localStorage.getItem("nro13a-dvctype") == "mobile") ? { background: "transparent" } : {}}
      >
        <Container fluid>

          {/* <Link
            className={"h4 mb-0 text-white text-uppercase d-none d-lg-inline-block direct-links" + ((href == "job-post")?" active":"")}
            to="/job-post"
            style={{marginLeft: "20px"}}
          >
            Jobs
          </Link> */}
          <div>
            {
              (localStorage.getItem("nro13a-dvctype") == "desktop")
                ? <NavbarBrand to="/index" tag={Link}>
                  <img
                    style={{ width: "130px" }}
                    alt="Home"
                    className="navbar-brand-img"
                    src={logo}
                  />
                </NavbarBrand>
                : ""
            }
            <Link
              className={"h4 mb-0 text-white text-uppercase d-none d-lg-inline-block direct-links" + ((href == "index") ? " active" : "")}
              to="/index"
            >
              Home
            </Link>
          </div>

          {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}
          <Nav className="align-items-center d-none d-md-flex" navbar>

            <Dropdown isOpen={openTooltip} className="custom-tooltip">
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem header tag="div">
                  <span>Update your profile here!</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown >

            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav onClick={() => { toggleTooltip(false) }}>
                <Media className="align-items-center">
                  <Media className="mr-3 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {props.ArrangeName(ls.name, 3)}
                    </span>
                  </Media>

                  <div className="avatar-thumbnail rounded-circle"
                    style={{
                      backgroundImage: `url(${(profilePic != null)
                        ? SERVER_URI + "images/users/" + profilePic
                        : SERVER_URI + "images/users/male-temp.png"
                        })`,
                      backgroundColor: "white"
                    }}
                  >
                  </div>

                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/my-profile/account" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My Profile</span>
                </DropdownItem>
                {/* <DropdownItem to="/my-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/my-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/my-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem> */}
                <DropdownItem divider />
                <DropdownItem onClick={(e) => {

                  localStorage.removeItem('nro13a-tkn');
                  localStorage.removeItem('nro13a-pp');
                  localStorage.removeItem('nro13a-info');
                  props.history.push("/auth/login");
                  window.location.reload();

                }}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NotificationArea />

          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => ({
})

export default withRouter(connect(mapStateToProps, {
  ArrangeName,
})(AdminNavbar));