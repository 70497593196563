export const SET_PROFILE_VALUES = 'SET_PROFILE_VALUES';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const UPDATE_CHILDREN = 'UPDATE_CHILDREN';
export const UPDATE_EDUCATION = 'UPDATE_EDUCATION';
export const UPDATE_ELIGIBILITY = 'UPDATE_ELIGIBILITY';
export const UPDATE_WORK_EXPERIENCE = 'UPDATE_WORK_EXPERIENCE';
export const UPDATE_VOLUNTARY_WORK = 'UPDATE_VOLUNTARY_WORK';
export const UPDATE_TRAINING = 'UPDATE_TRAINING';
export const UPDATE_SKILL = 'UPDATE_SKILL';
export const UPDATE_RECOGNITION = 'UPDATE_RECOGNITION';
export const UPDATE_REFERENCES = 'UPDATE_REFERENCES';
export const UPDATE_MEMBERSHIP = 'UPDATE_MEMBERSHIP';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_PROFILE_FILE = 'SET_PROFILE_FILE';
export const UPDATE_FILES = 'UPDATE_FILES';
export const SET_NEW_JOB_APPLIED = 'SET_NEW_JOB_APPLIED';
export const SET_SAVED_JOB_APPLIED = 'SET_SAVED_JOB_APPLIED';
export const SET_USER_AVATAR = 'SET_USER_AVATAR';
export const SET_PROFILE_COMPLETENESS = 'SET_PROFILE_COMPLETENESS';
