import { combineReducers } from 'redux';

import ProfileReducer from '../views/Profile/redux/reducer';
import JobPostReducer from '../views/JobPost/redux/reducer';
import AlertReducer from './alertReducer';

//=================~import reducer file~=================

export default combineReducers({
  Alert: AlertReducer,
  Profile: ProfileReducer,
  JobPost: JobPostReducer,
});

