/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

const UserHeader = () => {

  var banner = (localStorage.getItem("nro13a-dvctype") == "mobile")
    ? require(("../../assets/img/NEDA_TWITTER-BANNER-2048x683-mobile.jpg")).default
    : require(("../../assets/img/NEDA_TWITTER-BANNER-2048x683-desktop.png")).default;

    return (
    <>
      <div
        className="header header-user pb-7 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-custom"
        // // className="header header-user pb-8 bg-gradient-info pt-5 pt-lg-8 d-flex align-items-center"
        // style={{
        //   minHeight: "300px",
        //   backgroundImage:
        //     "url(" +
        //     banner +
        //     // require("../../assets/img/banner.jpg").default +
        //     ")",
        //   backgroundSize: "cover",
        //   backgroundPosition: "center top",
        //   backgroundPositionY: "center",
        // }}
      >
        {/* Mask */}
        <span className="mask  opacity-8" />
        {/* <span className="mask bg-gradient-default opacity-8" /> */}
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              {/* <h1 className="display-2 text-white">Hello Jesse</h1> */}
              <p className="text-white mt-0 mb-5">
                {/* This is your profile page. You can see the progress you've made
                with your work and manage your projects or assigned tasks */}
              </p>
              {/* <Button
                color="info"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                Edit profile
              </Button> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
