export const SET_JOB_POST = 'SET_JOB_POST';
export const SET_SEARCHED_JOB_POSTS = 'SET_SEARCHED_JOB_POSTS';
export const SET_JOB_POSTS = 'SET_JOB_POSTS';
export const ADD_NEW_JOB_POST = 'ADD_NEW_JOB_POST';
export const SET_JOB_POST_DETAIL = 'SET_JOB_POST_DETAIL';
export const SET_JOB_POST_VALUE = 'SET_JOB_POST_VALUE';
export const SET_JOB_POST_DEFAULT = 'SET_JOB_POST_DEFAULT';
export const SET_JOB_POSTS_RELATED = 'SET_JOB_POSTS_RELATED';
export const SET_SEARCHED_JOB_POSTS_RELATED = 'SET_SEARCHED_JOB_POSTS_RELATED';
export const SET_JOB_POSTS_MODAL = 'SET_JOB_POSTS_MODAL';
export const SET_SEARCHED_JOB_POSTS_MODAL = 'SET_SEARCHED_JOB_POSTS_MODAL';
