import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/custom.css";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import store from './store';
import { Provider } from 'react-redux';
import { GetMyInfo } from 'views/Profile/redux/actions';

ReactDOM.render(
  ((props) => {
    const getDeviceType = () => {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        return "mobile";
      }
      return "desktop";
    };

    const dvctype   = getDeviceType();
    const lsdvctype = localStorage.getItem("nro13a-dvctype");
    
    if (!lsdvctype || lsdvctype != dvctype) {
      localStorage.setItem("nro13a-dvctype",dvctype);
    }

    return (
      <BrowserRouter>
        <Switch>
          {
            (localStorage.getItem("nro13a-tkn") != null)
              ? <Fragment>
                <Route path="/" render={(props) => <Provider store={store}><AdminLayout {...props} /></Provider>} />
                <Redirect from="/" to="/index" />
                {/* <Redirect from="/auth/register" to="/admin/index" />
              <Route render={(props) => <Provider store={store}>404 Not Found</Provider>} /> */}
              </Fragment>
              : <Fragment>
                <Route path="/auth" render={(props) => <Provider store={store}><AuthLayout {...props} /></Provider>} />
                <Redirect from="/" to="/auth/login" />
              </Fragment>
          }
          {/* <Redirect from="/" to="/admin/index" /> */}
        </Switch>
      </BrowserRouter>
    )
  })(),
  document.getElementById("root")
);
