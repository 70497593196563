export const SERVER_API = 'https://hrmis-caraga.neda.gov.ph/api';
export const SERVER_URI = 'https://hrmis-caraga.neda.gov.ph/';

// export const SERVER_API = 'http://localhost:5000/api';
// export const SERVER_URI = 'http://localhost:5000/';

export const JWT = 'nro13a-tkn';
export const COURSES = {
    Elementary: ["Primary Education"],
    Secondary: ["Secondary Education"],
    College: [
        "Bachelor in Elementary Education (BEED)",
        "Bachelor in Elementary Education Major in Preschool Education (BEED)",
        "Bachelor in Elementary Education Major in Special Education (BEED)",
        "Bachelor in Landscape Architecture (BLA)",
        "Bachelor in Secondary Education (BSED)",
        "Bachelor in Secondary Education Major in Biological Sciences (BSED)",
        "Bachelor in Secondary Education Major in English (BSED)",
        "Bachelor in Secondary Education Major in Filipino (BSED)",
        "Bachelor in Secondary Education Major in Islamic Studies (BSED)",
        "Bachelor in Secondary Education Major in Mathematics (BSED)",
        "Bachelor in Secondary Education Major in Music, Arts, Physical and Health Education (BSED)",
        "Bachelor in Secondary Education Major in Physical Sciences (BSED)",
        "Bachelor in Secondary Education Major in Social Studies (BSED)",
        "Bachelor in Secondary Education Major in Technology and Livelihood Education (BSED)",
        "Bachelor in Secondary Education Major in Values Education (BSED)",
        "Bachelor of Arts in Anthropology (AB Anthropology)",
        "Bachelor of Arts in Broadcasting (AB Broadcasting)",
        "Bachelor of Arts in Communication (AB Communication)",
        "Bachelor of Arts in Economics (AB Economics)",
        "Bachelor of Arts in English (AB English)",
        "Bachelor of Arts in Filipino (AB Filipino)",
        "Bachelor of Arts in History (AB History)",
        "Bachelor of Arts in Islamic Studies (AB Islamic Studies)",
        "Bachelor of Arts in Journalism (AB Journalism)",
        "Bachelor of Arts in Linguistics (AB Linguistics)",
        "Bachelor of Arts in Literature (AB Literature)",
        "Bachelor of Arts in Mass Communication",
        "Bachelor of Arts in Philosophy (AB Philosophy)",
        "Bachelor of Arts in Political Science (AB Political Science)",
        "Bachelor of Arts in Psychology (AB Psychology)",
        "Bachelor of Arts in Sociology (AB Sociology)",
        "Bachelor of Fine Arts Major in Industrial Design (BFA)",
        "Bachelor of Fine Arts Major in Painting (BFA)",
        "Bachelor of Fine Arts Major in Sculpture (BFA)",
        "Bachelor of Fine Arts Major in Visual Communication (BFA)",
        "Bachelor of Library and Information Science in the Philippines (BLIS)",
        "Bachelor of Physical Education (BPE)",
        "Bachelor of Public Administration (BPA)",
        "Bachelor of Science in Accountancy (BSA)",
        "Bachelor of Science in Accounting Technology (BSAcT)",
        "Bachelor of Science in Aeronautical Engineering (BS AeroE)",
        "Bachelor of Science in Agribusiness (BS Agribusiness)",
        "Bachelor of Science in Agriculture",
        "Bachelor of Science in Agroforestry (BS Agroforestry)",
        "Bachelor of Science in Applied Mathematics (BS Applied Math)",
        "Bachelor of Science in Applied Physics (BS Applied Physics)",
        "Bachelor of Science in Architecture (BS Architecture)",
        "Bachelor of Science in Bachelor of Science in Hotel and Restaurant Management (BS HRM)",
        "Bachelor of Science in Biology (BS Biology)",
        "Bachelor of Science in Business Administration",
        "Bachelor of Science in Business Administration Major in Business Economics (BSBA)",
        "Bachelor of Science in Business Administration Major in Financial Management (BSBA major in FM)",
        "Bachelor of Science in Business Administration Major in Human Resource Development (BSBA major in HRDM)",
        "Bachelor of Science in Business Administration Major in Marketing Management (BSBA major in MM)",
        "Bachelor of Science in Business Administration Major in Operations Management (BSBA major in OM)",
        "Bachelor of Science in Ceramic Engineering (BSCerE)",
        "Bachelor of Science in Chemical Engineering (BSChE)",
        "Bachelor of Science in Chemistry (BS Chemistry)",
        "Bachelor of Science in Civil Engineering (BSCE)",
        "Bachelor of Science in Community Development(BS Community Development)",
        "Bachelor of Science in Computer Engineering (BSCpE)",
        "Bachelor of Science in Computer Science (BSCS)",
        "Bachelor of Science in Criminology (BS Criminology)",
        "Bachelor of Science in Customs Administration (BSCA)",
        "Bachelor of Science in Economics (BS Economics)",
        "Bachelor of Science in Electrical Engineering (BSEE)",
        "Bachelor of Science in Electronics and Communications Engineering (BSECE)",
        "Bachelor of Science in Entrepreneurship (BS Entrep)",
        "Bachelor of Science in Environmental Science (BSES)",
        "Bachelor of Science in Fisheries (BSFi)",
        "Bachelor of Science in Food Technology (BS Food Tech)",
        "Bachelor of Science in Foreign Service (BS Foreign Service)",
        "Bachelor of Science in Forensic Science (BSFS)",
        "Bachelor of Science in Forestry (BS Forestry)",
        "Bachelor of Science in Geodetic Engineering (BSGE)",
        "Bachelor of Science in Geological Engineering (BSGeoE)",
        "Bachelor of Science in Geology (BS Geology)",
        "Bachelor of Science in Industrial Engineering (BSIE)",
        "Bachelor of Science in Information Systems (BSIS)",
        "Bachelor of Science in Information Technology (BSIT)",
        "Bachelor of Science in Interior Design (BS Interior Design)",
        "Bachelor of Science in International Studies(BSIS)",
        "Bachelor of Science in Marine Engineering in (BSMarE)",
        "Bachelor of Science in Marine Transportation (BSMT)",
        "Bachelor of Science in Materials Engineering (BSMatE)",
        "Bachelor of Science in Mathematics (BS Mathematics)",
        "Bachelor of Science in Mechanical Engineering (BSME)",
        "Bachelor of Science in Medical Technology (BS Med Tech)",
        "Bachelor of Science in Metallurgical Engineering (BSMetE)",
        "Bachelor of Science in Midwifery (BS Midwifery)",
        "Bachelor of Science in Mining Engineering (BSEM)",
        "Bachelor of Science in Molecular Biology (BS Molecular Biology)",
        "Bachelor of Science in Nursing (BSN)",
        "Bachelor of Science in Nutrition and Dietetics (BS Nutrition and Dietetics)",
        "Bachelor of Science in Occupational Therapy (BSOT)",
        "Bachelor of Science in Office Administration (BSOA)",
        "Bachelor of Science in Petroleum Engineering (BSPetE)",
        "Bachelor of Science in Pharmacy (BS Pharmacy)",
        "Bachelor of Science in Physical Therapy (BSPT)",
        "Bachelor of Science in Physics (BS Physics)",
        "Bachelor of Science in Psychology (BS Psychology)",
        "Bachelor of Science in Public Safety (BSPS)",
        "Bachelor of Science in Radiologic Technology (BS Rad Tech)",
        "Bachelor of Science in Real Estate Management (BS REM)",
        "Bachelor of Science in Respiratory Therapy (BSRT)",
        "Bachelor of Science in Sanitary Engineering (BSSE)",
        "Bachelor of Science in Social Work (BS Social Work)",
        "Bachelor of Science in Speech-Language Pathology",
        "Bachelor of Science in Sports Science",
        "Bachelor of Science in Statistics (BS Stat)",
        "Bachelor of Science in Tourism Management (BSTM)",
        "Bachelor of Science in in Development Communication (BS DevComm)",
    ],
    Diploma: [],
    Master: [
        "Master of Accountancy",
        "Master of Advanced Study",
        "Masters of Agricultural Economics",
        "Master of Applied Finance",
        "Master of Applied Science",
        "Master of Architecture",
        "Master of Arts",
        "Master of Arts in Liberal Studies",
        "Master of Arts in Special Education",
        "Master of Arts in Teaching",
        "Master of Bioethics",
        "Master of Business Administration",
        "Master of Business, Entrepreneurship and Technology",
        "Master of Business",
        "Master of Business Engineering",
        "Master of Business Informatics",
        "Master of Chemistry",
        "Master of Christian Education",
        "Master of City Planning",
        "Master of Commerce",
        "Master of Computational Finance",
        "Master of Computer Applications",
        "Master of Counselling",
        "Master of Criminal Justice",
        "Master of Creative Technologies",
        "Master of Data Science",
        "Master of Defence Studies",
        "Master of Design",
        "Masters of Development Economics",
        "Master of Divinity",
        "Master of Economics",
        "Master of Education",
        "Master of Engineering",
        "Master of Engineering Management",
        "Master of Enterprise",
        "Master of European Law",
        "Master of Finance",
        "Master of Financial Economics",
        "Master of Financial Engineering",
        "Master of Financial Mathematics",
        "Master of Fine Arts",
        "Master of Health Administration",
        "Master of Health Economics",
        "Master of Health Science",
        "Master of Humanities",
        "Master of Industrial and Labor Relations",
        "Master of International Affairs",
        "Master of International Business",
        "Masters of International Economics",
        "Master of International Studies",
        "Master of Information and Cybersecurity",
        "Master of Information and Data Science",
        "Master of Information Management",
        "Master of Information System Management",
        "Master of Journalism",
        "Master of Jurisprudence",
        "Master of Laws",
        "Master of Mass Communication",
        "Master of Studies in Law",
        "Master of Landscape Architecture",
        "Master of Letters",
        "Master of Liberal Arts",
        "Master of Library and Information Science",
        "Master of Management",
        "Master of Management of Innovation",
        "Master of Mathematical Finance",
        "Master of Mathematics",
        "Master of Medical Science",
        "Master of Medicine",
        "Masters of Military Art and Science",
        "Master of Military Operational Art and Science",
        "Master of Ministry",
        "Master of Music",
        "Master of Occupational Behaviour and Development",
        "Master of Occupational Therapy",
        "Master of Pharmacy",
        "Master of Philosophy",
        "Master of Physician Assistant Studies",
        "Master of Physics",
        "Master of Political Science",
        "Master of Professional Studies",
        "Master of Psychology",
        "Master of Public Administration",
        "Master of Public Affairs",
        "Master of Public Health",
        "Master of Public Management",
        "Master of Public Policy",
        "Master of Public Relations",
        "Master of Public Service",
        "Master of Quantitative Finance",
        "Master of Rabbinic Studies",
        "Master of Real Estate Development",
        "Master of Religious Education",
        "Master of Research",
        "Master of Sacred Music",
        "Master of Sacred Theology",
        "Master of Science",
        "Master of Science in Administration",
        "Master of Science in Archaeology",
        "Master of Science in Biblical Archaeology",
        "Master of Science in Bioinformatics",
        "Master of Science in Computer Science",
        "Master of Science in Counselling",
        "Master of Science in Cyber Security",
        "Master of Science in Engineering",
        "Master of Science in Development Administration",
        "Master of Science in Finance",
        "Master of Science in Health Informatics",
        "Master of Science in Human Resource Development",
        "Master of Science in Information Assurance",
        "Master of Science in Information Systems",
        "Master of Science in Information Technology",
        "Master of Science in Leadership",
        "Master of Science in Management",
        "Master of Science in Nursing",
        "Master of Science in Project Management",
        "Master of Science in Supply Chain Management",
        "Master of Science in Teaching",
        "Master of Science in Taxation",
        "Master of Social Science",
        "Master of Social Work",
        "Master of Strategic Studies",
        "Master of Studies",
        "Master of Surgery",
        "Master of Talmudic Law",
        "Master of Taxation",
        "Master of Theological Studies",
        "Master of Technology",
        "Master of Theology",
        "Master of Urban Planning",
        "Master of Veterinary Science",    
    ],
    Doctor: [
        "Doctor of Architecture",
        "Doctor of Arts",
        "Doctor of Arts in Teaching",
        "Doctor of Applied Science",
        "Doctor of Business Administration",
        "Doctor of Canon Law",
        "Doctor of Chemistry",
        "Doctor of Church Music",
        "Doctor of Criminal Justice",
        "Doctor of Criminology",
        "Doctor of Comparative/Civil Law",
        "Doctor of Design",
        "Doctor of Education",
        "Doctor of Engineering",
        "Doctor of Environment",
        "Doctor of Fine Arts",
        "Doctor of Forestry",
        "Doctor of Geological Science",
        "Doctor of Health and Safety",
        "Doctor of Hebrew Letters/Literature",
        "Doctor of Hebrew Studies",
        "Doctor of Industrial Technology",
        "Doctor of Juridical Science",
        "Doctor of Juristic Science",
        "Doctor of Library Science",
        "Doctor of Medical Science",
        "Doctor of Missiology",
        "Doctor of Music",
        "Doctor of Music Education",
        "Doctor of Music Ministry",
        "Doctor of Musical Arts",
        "Doctor of Modern Languages",
        "Doctor of Nursing Science",
        "Doctor of Philosophy",
        "Doctor of Physical Education",
        "Doctor of Professional Studies",
        "Doctor of Public Administration",
        "Doctor of Public Health",
        "Doctor of Recreation",
        "Doctor of Rehabilitation",
        "Doctor of Religious Education",
        "Doctor of Sacred Music",
        "Doctor of Sacred Theology",
        "Doctor of Science",
        "Doctor of Science and Hygiene",
        "Doctor of Science in Dentistry",
        "Doctor of Science in Veterinary Medicine",
        "Doctor of Science of Law",
        "Doctor of Social Science",
        "Doctor of Social Work",
        "Doctor of Theology",
    ],
}
