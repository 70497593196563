/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
import { connect } from 'react-redux';

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
} from "reactstrap";

import { GetMyInfo } from './Profile/redux/actions';

import Header from "components/Headers/Header.js";
import JobPost from "./JobPost";
import JobPostStatus from "./JobPost/forms/JobPostStatus.js";
import InfoModal from "components/Helpers/InfoModal.js";
import { SERVER_URI } from 'config';

const Index = (props) => {

  if (!props.Profile.values._id) {
    props.GetMyInfo();
  }

  const [modalApplied, setModalApplied] = useState(false);
  const [modalType, setModalType] = useState("");

  const toggleJobStatus = (type) => {
    setModalApplied(!modalApplied);
    setModalType(type);
  };

  var banner = (localStorage.getItem("nro13a-dvctype") == "mobile")
    ? require(("../assets/img/NEDA_TWITTER-BANNER-2048x683-mobile.jpg")).default
    // : require(("../assets/img/Banner/NEDA_TWITTER-BANNER-2048x683-1.png")).default;
    : require(("../assets/img/NEDA_TWITTER-BANNER-2048x683-desktop.png")).default;
  // 
  return (
    <>
      <InfoModal
        size={"80%"}
        modal={modalApplied}
        toggle={() => { toggleJobStatus(modalType) }}
        modalType={modalType}
        title={modalType}
        form={<JobPostStatus modalType={modalType} />}
        buttons={[]}
      />

      <div
        id="header"
        className="header bg-gradient-custom pb-2 pt-5 pt-md-8"
        style={{
          minHeight: "300px",
        //   backgroundImage:
        //     "url(" +
        //     banner +
        //     // require("../assets/img/banner.jpg").default +
        //     ")",
        //   backgroundSize: "cover",
        //   backgroundPosition: "center top",
        //   backgroundPositionY: "center",
        }}
      >
        {/* <img src={require("./NEDA_TWITTER-BANNER-2048x683.jpg").default}/> */}


        <div className="header-counts">
          <div>
            <Card className="card-stats mb-1">
              <CardBody className="clickable" onClick={() => { toggleJobStatus("Applied") }}>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h6"
                      className="text-uppercase text-muted mb-0"
                    >
                      Job Applied
                    </CardTitle>
                    <span className="h4 font-weight-bold mb-0">
                      {props.Profile.values.appliedJobs.length}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                      <i className="fas fa-briefcase" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <div>
            <Card className="card-stats mb-1">
              <CardBody className="clickable" onClick={() => { toggleJobStatus("Saved") }}>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h6"
                      className="text-uppercase text-muted mb-0"
                    >
                      Saved Jobs
                    </CardTitle>
                    <span className="h4 font-weight-bold mb-0">
                      {props.Profile.values.savedJobs.length}

                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                      <i className="fas fa-archive" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          {/* <div>
            <Card className="card-stats mb-1">
              <CardBody className="clickable" onClick={() => { toggleJobStatus("Related") }}>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h6"
                      className="text-uppercase text-muted mb-0"
                    >
                      Related Job
                    </CardTitle>
                    <span className="h4 font-weight-bold mb-0">{props.JobPost.related.count}</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                      <i className="fas fa-users" />
                    </div>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </div> */}
        </div>
      </div>
      {/* Page content */}
      <JobPost className="wo-pt" />
    </>
  );
};

const mapStateToProps = (state) => ({
  Profile: state.Profile,
  JobPost: state.JobPost,
})

export default connect(mapStateToProps, {
  GetMyInfo,
})(Index);
