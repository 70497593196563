import {
    SET_JOB_POST_DETAIL,
} from './types';
import {
    SET_NEW_JOB_APPLIED,
    SET_PROFILE_VALUES,
    SET_SAVED_JOB_APPLIED,
} from '../../Profile/redux/types';
// import { SERVER_API, JWT } from '../config';
import axios from 'axios';
import toast from 'react-hot-toast';
import { SERVER_API, JWT } from 'config';

export const SetJobPostDetail = (id, list = null) => (dispatch, getState) => {

    if (!list) {
        list = getState().JobPost.toDisplay;
    }


    const jobPost = list.filter((jobPost) => jobPost._id == id);

    dispatch({
        type: SET_JOB_POST_DETAIL,
        detail: {
            values: [...jobPost]
        }
    })
}

export const Apply = (data) => (dispatch, getState) => {
    const jobVal = getState().JobPost.values;
    const proVal = getState().Profile.values;


    var alreadyApplied = false;

    for (let x = 0; x < proVal.appliedJobs.length; x++) {
        if (proVal.appliedJobs[x].job == jobVal._id) {
            alreadyApplied = true;
        }
    }

    toast.promise(

        new Promise((resolve, reject) => {
            if (!alreadyApplied) {

                axios({
                    url: `${SERVER_API}/job-post/apply`,
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem(JWT)}`
                    },
                    data: {
                        job: jobVal._id,
                        applicant: proVal._id,
                    }
                })
                    .then((res) => {

                        if (res.data.success) {
                            dispatch({
                                type: SET_NEW_JOB_APPLIED,
                                id: jobVal._id
                            })
                            resolve();
                        }
                    })
                    .catch(err => {
                        reject();
                        alert(err);
                        // this.props.ToggleAlert("failed", 'Login Failed', true);
                    })
            } else {
                resolve();
            }
        })
        , {
            loading: 'Sending Application...',
            success: 'Application Sent',
            error: 'Failed to Apply',
        }, {
        loading: {
            duration: Infinity
        },
        success: {
            duration: 3000
        }
    }
    );

}


export const Save = (data) => (dispatch, getState) => {
    const jobVal = getState().JobPost.values;
    const proVal = getState().Profile.values;


    var alreadySaved = false;

    for (let x = 0; x < proVal.savedJobs.length; x++) {
        if (proVal.savedJobs[x].job == jobVal._id) {
            alreadySaved = true;
        }
    }

    toast.promise(
        new Promise((resolve, reject) => {
            if (!alreadySaved) {
                axios({
                    url: `${SERVER_API}/user/save-job`,
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem(JWT)}`
                    },
                    data: {
                        job: jobVal._id,
                    }
                })
                    .then((res) => {

                        if (res.data.success) {
                            dispatch({
                                type: SET_SAVED_JOB_APPLIED,
                                id: jobVal._id
                            })
                            resolve();
                        }
                    })
                    .catch(err => {
                        reject();
                        alert(err);
                        // this.props.ToggleAlert("failed", 'Login Failed', true);
                    })
            } else {
                resolve();
            }
        })
        , {
            loading: 'Saving...',
            success: 'Job Saved',
            error: 'Failed to Save',
        }, {
        loading: {
            duration: Infinity
        },
        success: {
            duration: 3000
        }
    }
    );

}

export const WithdrawApplication = (jobID) => (dispatch, getState) => {
    const jobVal = getState().JobPost.values;
    const proVal = getState().Profile.values;

    toast.promise(
        new Promise((resolve, reject) => {
            if (true) {
                axios({
                    url: `${SERVER_API}/job-post/withdraw-application`,
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem(JWT)}`
                    },
                    data: {
                        job: jobID,
                        applicant: proVal._id,
                    }
                })
                    .then((res) => {
                        if (res.data.success) {
                            dispatch({
                                type: SET_PROFILE_VALUES,
                                values: {
                                    ...proVal,
                                    appliedJobs: proVal.appliedJobs.filter(job => job.job != jobID)
                                }
                            })
                            resolve();
                        }
                    })
                    .catch(err => {
                        reject();
                    })
            } else {
                resolve();
            }
        })
        , {
            loading: 'Withdrawing Application...',
            success: 'Application Withdrawn',
            error: 'Failed',
        }, {
        loading: {
            duration: Infinity
        },
        success: {
            duration: 3000
        }
    }
    );
}