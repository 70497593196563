import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { FaRegTimesCircle } from "react-icons/fa";

import { FaFileExcel, FaFileDownload, FaExclamationCircle, FaCheckCircle, } from 'react-icons/fa';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
} from "reactstrap";
import LabelInput from 'components/Helpers/LabelInput';
import InfoModal from "components/Helpers/InfoModal.js";
import FileUpload from 'components/Helpers/FileUpload';
import { SetValue, ArrangeDate } from 'store/actions/helpers/displayAction.js';
import { GetDate } from 'store/actions/helpers/dateAction.js';
import { SET_PROFILE_DATA } from '../redux/types';
import { UpdateReferences, GetProfileFile, SaveProfileFile, AddCertificates, RemoveCertificate, } from '../redux/actions';
import { SERVER_URI } from 'config';

class Form_12_upload_files extends Component {

    constructor(props) {
        super(props);

        var fPDS = null, fRes = null, fApp = null, fEli = null, fDip = null, fCav = null, fTor = null;

        for (let i = 0; i < this.props.pValues.files.length; i++) {
            if (this.props.pValues.files[i].type == "pds" && this.props.pValues.files[i].status == "current") {
                fPDS = { ...this.props.pValues.files[i] };
                break;
            }
        }

        // for (let i = 0; i < this.props.pValues.files.length; i++) {
        //     if (this.props.pValues.files[i].type == "resume" && this.props.pValues.files[i].status == "current") {
        //         fRes = { ...this.props.pValues.files[i] };
        //         break;
        //     }
        // }

        for (let i = 0; i < this.props.pValues.files.length; i++) {
            if (this.props.pValues.files[i].type == "app-letter" && this.props.pValues.files[i].status == "current") {
                fApp = { ...this.props.pValues.files[i] };
                break;
            }
        }

        for (let i = 0; i < this.props.pValues.files.length; i++) {
            if (this.props.pValues.files[i].type == "eligibility" && this.props.pValues.files[i].status == "current") {
                fEli = { ...this.props.pValues.files[i] };
                break;
            }
        }

        for (let i = 0; i < this.props.pValues.files.length; i++) {
            if (this.props.pValues.files[i].type == "diploma" && this.props.pValues.files[i].status == "current") {
                fDip = { ...this.props.pValues.files[i] };
                break;
            }
        }

        for (let i = 0; i < this.props.pValues.files.length; i++) {
            if (this.props.pValues.files[i].type == "cav" && this.props.pValues.files[i].status == "current") {
                fCav = { ...this.props.pValues.files[i] };
                break;
            }
        }

        for (let i = 0; i < this.props.pValues.files.length; i++) {
            if (this.props.pValues.files[i].type == "tor" && this.props.pValues.files[i].status == "current") {
                fTor = { ...this.props.pValues.files[i] };
                break;
            }
        }

        this.state = {
            fPDS: fPDS,
            // fRes: fRes,
            fApp: fApp,
            fEli: fEli,
            fDip: fDip,
            fCav: fCav,
            fTor: fTor,

            modalCert: false,

            certFile: {
                file: null,
                name: ""
            },

            toDelete: null,

            fileTypes: {
                pds: true,
                appL: false,
                eli: false,
                dip: false,
                tor: false,
                cav: false,
                cert: false,
            }
        }

        this.toggleModalCert = this.toggleModalCert.bind(this);
        this.createNav = this.createNav.bind(this);


    }

    toggleModalCert() {
        this.setState({
            modalCert: !this.state.modalCert
        })
    }

    createNav(name, code, file) {
        return (
            <li class="nav-item clickable"
                onClick={() => {
                    var fileTypes = { ...this.state.fileTypes }
                    var key = Object.keys(fileTypes);
                    key.map((k) => {
                        fileTypes[k] = (k == code) ? true : false
                    })


                    this.setState({
                        fileTypes: fileTypes
                    })
                }}
            >
                <a class={"nav-link mb-0 px-0 py-1" + ((this.state.fileTypes[code]) ? " active" : "")} data-bs-toggle="tab" role="tab" aria-controls={name} aria-selected="true">
                    <div className="file-stat-icons">
                        {name}
                        {
                            // (file && true)
                            //     ? <FaCheckCircle className="warn" style={{ color: "#00a1df" }} />
                            //     : <FaExclamationCircle className="warn" style={{ color: "#fc9d01" }} />
                        }
                        {
                            (file)
                                ? <FaFileDownload style={{ color: "#209f00" }} />
                                : <FaFileExcel style={{ color: "#c01f2f" }} />
                        }

                        {
                            (!file && (name == "PDS" || name == "Application letter" || name == "Eligibility" || name == "TOR"))
                                ? <FaExclamationCircle className="warn" />
                                : ""
                        }


                    </div>

                </a>
            </li>
        )
    }

    allowButton(type) {
        const files = [...this.props.Profile.tempFiles];
        var file = null;
        for (let i = 0; i < files.length; i++) {
            if (files[i].type == type) {
                file = { ...files[i] };
                break;
            }
        }

        return file ? true : false;
    }


    render() {

        return (
            <>
                <InfoModal
                    size={"40%"}
                    modal={this.state.modalCert}
                    toggle={this.toggleModalCert}
                    title={""}
                    form={<Fragment>
                        <Col lg="12">
                            <LabelInput
                                label={"Name of certificate"}
                                type="text"
                                value={this.state.certFile.name}
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        certFile: {
                                            ...this.state.certFile,
                                            name: e.target.value
                                        }
                                    })
                                }}
                            />
                        </Col>

                        <Col lg="12"><br />
                            <div className="pl-lg-4">
                                <FileUpload
                                    GetProfileFile={(file) => {
                                        this.setState({
                                            certFile: {
                                                ...this.state.certFile,
                                                file: file
                                            }
                                        })
                                    }}
                                    file={null}
                                    filetype="image/*"
                                />
                            </div>
                        </Col>

                    </Fragment>}
                    buttons={[{
                        type: "Save", disable: (this.state.certFile.name != "" && this.state.certFile.file != null) ? false : true,
                        callback: async () => {
                            await this.props.AddCertificates(this.state.certFile.file, this.state.certFile.name, () => {
                                this.setState({
                                    certFile: {
                                        file: null,
                                        name: ""
                                    },
                                })
                            })
                        }
                    }]}
                />



                <Col id="form-container" className="order-xl-1" xl="8">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">Related Documents</h3>
                                </Col>
                                {/* <Col className="text-right" xs="4">
                                <Button
                                    color="primary"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                    size="sm"
                                >
                                    Edit
                                </Button>
                            </Col> */}
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {
                                (localStorage.getItem("nro13a-dvctype") == "desktop")
                                    ? <div class="nav-wrapper position-relative end-0 file-types">
                                        <ul class="nav nav-pills nav-fill p-1" role="tablist">
                                            {this.createNav("PDS", "pds", this.state.fPDS)}
                                            {this.createNav("Application letter", "appL", this.state.fApp)}
                                        </ul>
                                        <ul class="nav nav-pills nav-fill p-1" role="tablist">
                                            {this.createNav("Eligibility", "eli", this.state.fEli)}
                                            {this.createNav("Diploma", "dip", this.state.fDip)}
                                        </ul>
                                        <ul class="nav nav-pills nav-fill p-1" role="tablist">
                                            {this.createNav("TOR", "tor", this.state.fTor)}
                                            {this.createNav("CAV", "cav", this.state.fCav)}
                                        </ul>
                                        <ul class="nav nav-pills nav-fill p-1" role="tablist">
                                            {this.createNav("Certificates", "cert")}
                                        </ul>
                                    </div>
                                    : <div class="nav-wrapper position-relative end-0 file-types">
                                        <ul class="nav nav-pills nav-fill p-1" role="tablist">
                                            {this.createNav("PDS", "pds", this.state.fPDS)}
                                        </ul>
                                        <ul class="nav nav-pills nav-fill p-1" role="tablist">
                                            {this.createNav("Application letter", "appL", this.state.fApp)}
                                        </ul>
                                        <ul class="nav nav-pills nav-fill p-1" role="tablist">
                                            {this.createNav("Eligibility", "eli", this.state.fEli)}
                                        </ul>
                                        <ul class="nav nav-pills nav-fill p-1" role="tablist">
                                            {this.createNav("Diploma", "dip", this.state.fDip)}
                                        </ul>
                                        <ul class="nav nav-pills nav-fill p-1" role="tablist">
                                            {this.createNav("TOR", "tor", this.state.fTor)}
                                        </ul>
                                        <ul class="nav nav-pills nav-fill p-1" role="tablist">
                                            {this.createNav("CAV", "cav", this.state.fCav)}
                                        </ul>
                                        <ul class="nav nav-pills nav-fill p-1" role="tablist">
                                            {this.createNav("Certificates", "cert")}
                                        </ul>
                                    </div>
                            }
                            {
                                (this.state.fileTypes.pds)
                                    ? <Form>
                                        <Row className="align-items-center pl-2">
                                            <Col lg="12">
                                                <h3 className="mb-0">PDS</h3>
                                            </Col>

                                            <Col lg="12"><br />
                                                <div className="pl-lg-4">
                                                    <FileUpload
                                                        GetProfileFile={(file) => {
                                                            this.props.GetProfileFile(file, "pds");
                                                        }}
                                                        file={this.state.fPDS}
                                                        doctype="pds"
                                                    />
                                                    {
                                                        (this.state.fPDS != null)
                                                            ? <div>
                                                                <h6 className="heading-small text-muted">
                                                                    <strong>Name:</strong> {this.state.fPDS.name}
                                                                </h6>
                                                                <h6 className="heading-small text-muted">
                                                                    <strong>Uploaded:</strong> {this.props.ArrangeDate(this.state.fPDS.dateUploaded)}
                                                                </h6>
                                                            </div>
                                                            : ""

                                                    }
                                                </div>
                                            </Col>


                                            {
                                                (this.state.fPDS != null)
                                                    ? <Col className="text-right" lg="12"><br />
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    fPDS: null
                                                                })
                                                            }}
                                                            size="sm"
                                                        >
                                                            Upload New File
                                                        </Button>
                                                    </Col>
                                                    : <Col className="text-right" lg="12"><br />
                                                        <Button
                                                            color="primary"
                                                            disabled={!this.allowButton("pds")}
                                                            onClick={async () => {
                                                                if (!this.allowButton("pds")) return;

                                                                await this.props.SaveProfileFile("pds", () => {
                                                                    var f = null;

                                                                    for (let i = 0; i < this.props.pValues.files.length; i++) {
                                                                        if (this.props.pValues.files[i].type == "pds" && this.props.pValues.files[i].status == "current") {
                                                                            f = { ...this.props.pValues.files[i] };
                                                                            break;
                                                                        }
                                                                    }
                                                                    this.setState({
                                                                        fPDS: f
                                                                    })

                                                                });

                                                            }}
                                                            size="sm"
                                                        >
                                                            Save File
                                                        </Button>
                                                    </Col>
                                            }



                                        </Row>
                                        <hr className="my-4" />

                                    </Form>
                                    : ""
                            }

                            {
                                (this.state.fileTypes.appL)
                                    ? <Form>
                                        <Row className="align-items-center pl-2">
                                            <Col lg="12">
                                                <h3 className="mb-0">Application Letter</h3>
                                            </Col>

                                            <Col lg="12"><br />
                                                <div className="pl-lg-4">
                                                    <FileUpload
                                                        GetProfileFile={(file) => {
                                                            this.props.GetProfileFile(file, "app-letter");
                                                        }}
                                                        file={this.state.fApp}
                                                        doctype="app-letter"
                                                    />
                                                    {
                                                        (this.state.fApp != null)
                                                            ? <div>
                                                                <h6 className="heading-small text-muted">
                                                                    <strong>Name:</strong> {this.state.fApp.name}
                                                                </h6>
                                                                <h6 className="heading-small text-muted">
                                                                    <strong>Uploaded:</strong> {this.props.ArrangeDate(this.state.fApp.dateUploaded)}
                                                                </h6>
                                                            </div>
                                                            : ""

                                                    }
                                                </div>
                                            </Col>

                                            {
                                                (this.state.fApp != null)
                                                    ? <Col className="text-right" lg="12"><br />
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    fApp: null
                                                                })
                                                            }}
                                                            size="sm"
                                                        >
                                                            Upload New File
                                                        </Button>
                                                    </Col>
                                                    : <Col className="text-right" lg="12"><br />
                                                        <Button
                                                            color="primary"
                                                            disabled={!this.allowButton("app-letter")}
                                                            onClick={async () => {
                                                                if (!this.allowButton("app-letter")) return;

                                                                await this.props.SaveProfileFile("app-letter", () => {
                                                                    var f = null;

                                                                    for (let i = 0; i < this.props.pValues.files.length; i++) {
                                                                        if (this.props.pValues.files[i].type == "app-letter" && this.props.pValues.files[i].status == "current") {
                                                                            f = { ...this.props.pValues.files[i] };
                                                                            break;
                                                                        }
                                                                    }
                                                                    this.setState({
                                                                        fApp: f
                                                                    })

                                                                });
                                                            }}
                                                            size="sm"
                                                        >
                                                            Save File
                                                        </Button>
                                                    </Col>
                                            }

                                        </Row>
                                        <hr className="my-4" />

                                    </Form>
                                    : ""
                            }

                            {
                                (this.state.fileTypes.eli)
                                    ? <Form>
                                        <Row className="align-items-center pl-2">
                                            <Col lg="12">
                                                <h3 className="mb-0">Eligibility</h3>
                                            </Col>

                                            <Col lg="12"><br />
                                                <div className="pl-lg-4">
                                                    <FileUpload
                                                        GetProfileFile={(file) => {
                                                            this.props.GetProfileFile(file, "eligibility");
                                                        }}
                                                        file={this.state.fEli}
                                                        doctype="eligibility"
                                                    />
                                                    {
                                                        (this.state.fEli != null)
                                                            ? <div>
                                                                <h6 className="heading-small text-muted">
                                                                    <strong>Name:</strong> {this.state.fEli.name}
                                                                </h6>
                                                                <h6 className="heading-small text-muted">
                                                                    <strong>Uploaded:</strong> {this.props.ArrangeDate(this.state.fEli.dateUploaded)}
                                                                </h6>
                                                            </div>
                                                            : ""

                                                    }
                                                </div>
                                            </Col>

                                            {
                                                (this.state.fEli != null)
                                                    ? <Col className="text-right" lg="12"><br />
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    fEli: null
                                                                })
                                                            }}
                                                            size="sm"
                                                        >
                                                            Upload New File
                                                        </Button>
                                                    </Col>
                                                    : <Col className="text-right" lg="12"><br />
                                                        <Button
                                                            color="primary"
                                                            disabled={!this.allowButton("eligibility")}
                                                            onClick={async () => {
                                                                if (!this.allowButton("eligibility")) return;

                                                                await this.props.SaveProfileFile("eligibility", () => {
                                                                    var f = null;

                                                                    for (let i = 0; i < this.props.pValues.files.length; i++) {
                                                                        if (this.props.pValues.files[i].type == "eligibility" && this.props.pValues.files[i].status == "current") {
                                                                            f = { ...this.props.pValues.files[i] };
                                                                            break;
                                                                        }
                                                                    }
                                                                    this.setState({
                                                                        fEli: f
                                                                    })

                                                                });
                                                            }}
                                                            size="sm"
                                                        >
                                                            Save File
                                                        </Button>
                                                    </Col>
                                            }

                                        </Row>
                                        <hr className="my-4" />

                                    </Form>
                                    : ""
                            }

                            {
                                (this.state.fileTypes.dip)
                                    ? <Form>
                                        <Row className="align-items-center pl-2">
                                            <Col lg="12">
                                                <h3 className="mb-0">Diploma</h3>
                                            </Col>

                                            <Col lg="12"><br />
                                                <div className="pl-lg-4">
                                                    <FileUpload
                                                        GetProfileFile={(file) => {
                                                            this.props.GetProfileFile(file, "diploma");
                                                        }}
                                                        file={this.state.fDip}
                                                        doctype="diploma"
                                                    />
                                                    {
                                                        (this.state.fDip != null)
                                                            ? <div>
                                                                <h6 className="heading-small text-muted">
                                                                    <strong>Name:</strong> {this.state.fDip.name}
                                                                </h6>
                                                                <h6 className="heading-small text-muted">
                                                                    <strong>Uploaded:</strong> {this.props.ArrangeDate(this.state.fDip.dateUploaded)}
                                                                </h6>
                                                            </div>
                                                            : ""

                                                    }
                                                </div>
                                            </Col>

                                            {
                                                (this.state.fDip != null)
                                                    ? <Col className="text-right" lg="12"><br />
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    fDip: null
                                                                })
                                                            }}
                                                            size="sm"
                                                        >
                                                            Upload New File
                                                        </Button>
                                                    </Col>
                                                    : <Col className="text-right" lg="12"><br />
                                                        <Button
                                                            color="primary"
                                                            disabled={!this.allowButton("diploma")}
                                                            onClick={async () => {
                                                                if (!this.allowButton("diploma")) return;

                                                                await this.props.SaveProfileFile("diploma", () => {
                                                                    var f = null;

                                                                    for (let i = 0; i < this.props.pValues.files.length; i++) {
                                                                        if (this.props.pValues.files[i].type == "diploma" && this.props.pValues.files[i].status == "current") {
                                                                            f = { ...this.props.pValues.files[i] };
                                                                            break;
                                                                        }
                                                                    }
                                                                    this.setState({
                                                                        fDip: f
                                                                    })

                                                                });
                                                            }}
                                                            size="sm"
                                                        >
                                                            Save File
                                                        </Button>
                                                    </Col>
                                            }

                                        </Row>
                                        <hr className="my-4" />

                                    </Form>
                                    : ""
                            }

                            {
                                (this.state.fileTypes.tor)
                                    ? <Form>
                                        <Row className="align-items-center pl-2">
                                            <Col lg="12">
                                                <h3 className="mb-0">Transcript of Record (TOR)</h3>
                                            </Col>

                                            <Col lg="12"><br />
                                                <div className="pl-lg-4">
                                                    <FileUpload
                                                        GetProfileFile={(file) => {
                                                            this.props.GetProfileFile(file, "tor");
                                                        }}
                                                        file={this.state.fTor}
                                                        doctype="tor"
                                                    />
                                                    {
                                                        (this.state.fTor != null)
                                                            ? <div>
                                                                <h6 className="heading-small text-muted">
                                                                    <strong>Name:</strong> {this.state.fTor.name}
                                                                </h6>
                                                                <h6 className="heading-small text-muted">
                                                                    <strong>Uploaded:</strong> {this.props.ArrangeDate(this.state.fTor.dateUploaded)}
                                                                </h6>
                                                            </div>
                                                            : ""

                                                    }
                                                </div>
                                            </Col>

                                            {
                                                (this.state.fTor != null)
                                                    ? <Col className="text-right" lg="12"><br />
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    fTor: null
                                                                })
                                                            }}
                                                            size="sm"
                                                        >
                                                            Upload New File
                                                        </Button>
                                                    </Col>
                                                    : <Col className="text-right" lg="12"><br />
                                                        <Button
                                                            color="primary"
                                                            disabled={!this.allowButton("tor")}
                                                            onClick={async () => {
                                                                if (!this.allowButton("tor")) return;

                                                                await this.props.SaveProfileFile("tor", () => {
                                                                    var f = null;

                                                                    for (let i = 0; i < this.props.pValues.files.length; i++) {
                                                                        if (this.props.pValues.files[i].type == "tor" && this.props.pValues.files[i].status == "current") {
                                                                            f = { ...this.props.pValues.files[i] };
                                                                            break;
                                                                        }
                                                                    }
                                                                    this.setState({
                                                                        fTor: f
                                                                    })

                                                                });
                                                            }}
                                                            size="sm"
                                                        >
                                                            Save File
                                                        </Button>
                                                    </Col>
                                            }

                                        </Row>
                                        <hr className="my-4" />

                                    </Form>
                                    : ""
                            }

                            {
                                (this.state.fileTypes.cav)
                                    ? <Form>
                                        <Row className="align-items-center pl-2">
                                            <Col lg="12">
                                                <h3 className="mb-0">Certification, Authentication, and Verification (CAV)</h3>
                                            </Col>

                                            <Col lg="12"><br />
                                                <div className="pl-lg-4">
                                                    <FileUpload
                                                        GetProfileFile={(file) => {
                                                            this.props.GetProfileFile(file, "cav");
                                                        }}
                                                        file={this.state.fCav}
                                                        doctype="cav"
                                                    />
                                                    {
                                                        (this.state.fCav != null)
                                                            ? <div>
                                                                <h6 className="heading-small text-muted">
                                                                    <strong>Name:</strong> {this.state.fCav.name}
                                                                </h6>
                                                                <h6 className="heading-small text-muted">
                                                                    <strong>Uploaded:</strong> {this.props.ArrangeDate(this.state.fCav.dateUploaded)}
                                                                </h6>
                                                            </div>
                                                            : ""

                                                    }
                                                </div>
                                            </Col>

                                            {
                                                (this.state.fCav != null)
                                                    ? <Col className="text-right" lg="12"><br />
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    fCav: null
                                                                })
                                                            }}
                                                            size="sm"
                                                        >
                                                            Upload New File
                                                        </Button>
                                                    </Col>
                                                    : <Col className="text-right" lg="12"><br />
                                                        <Button
                                                            color="primary"
                                                            disabled={!this.allowButton("cav")}
                                                            onClick={async () => {
                                                                if (!this.allowButton("cav")) return;

                                                                await this.props.SaveProfileFile("cav", () => {
                                                                    var f = null;

                                                                    for (let i = 0; i < this.props.pValues.files.length; i++) {
                                                                        if (this.props.pValues.files[i].type == "cav" && this.props.pValues.files[i].status == "current") {
                                                                            f = { ...this.props.pValues.files[i] };
                                                                            break;
                                                                        }
                                                                    }
                                                                    this.setState({
                                                                        fCav: f
                                                                    })

                                                                });
                                                            }}
                                                            size="sm"
                                                        >
                                                            Save File
                                                        </Button>
                                                    </Col>
                                            }

                                        </Row>
                                        <hr className="my-4" />

                                    </Form>
                                    : ""
                            }

                            {
                                (this.state.fileTypes.cert)
                                    ? <Form>
                                        <Row className="align-items-center pl-2">
                                            <Col className="text-left" lg="12"><br />
                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        this.toggleModalCert();
                                                    }}
                                                    size="sm"
                                                >
                                                    Upload Certificates
                                                </Button>
                                            </Col>
                                            <Row id="certificates">

                                                {
                                                    this.props.pValues.files.map((cert, i) => {
                                                        return (cert.type == "certificate" && cert.status == "current") ? (
                                                            <div className="col-md-4 cert-div">
                                                                <div className="userAvatar"
                                                                    style={{
                                                                        backgroundImage: `url(${SERVER_URI + "files/certificate/" + cert.path})`
                                                                    }}
                                                                >

                                                                    {
                                                                        (cert._id == this.state.toDelete)
                                                                            ? ""
                                                                            : <i class="ni ni-fat-remove" onClick={() => {
                                                                                this.setState({
                                                                                    toDelete: cert._id
                                                                                })
                                                                            }}></i>
                                                                    }


                                                                    {
                                                                        (cert._id == this.state.toDelete)
                                                                            ? <div id="remove-confirm-div">
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        this.props.RemoveCertificate(cert._id)
                                                                                    }}
                                                                                    size="sm"
                                                                                >
                                                                                    Yes
                                                                                </Button>
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            toDelete: null
                                                                                        })
                                                                                    }}
                                                                                    size="sm"
                                                                                >
                                                                                    No
                                                                                </Button>
                                                                            </div>
                                                                            : ""
                                                                    }

                                                                    {/* <div id="remove-confirm-div" style={(cert._id == this.state.toDelete)?{display:"flex"}:{display:"none"}}>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                        }}
                                                                        size="sm"
                                                                    >
                                                                        Yes
                                                                    </Button>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                        }}
                                                                        size="sm"
                                                                    >
                                                                        No
                                                                    </Button>
                                                                </div> */}

                                                                </div>

                                                                <div className="img-details">
                                                                    <h6 className="heading-small text-muted">
                                                                        <strong>Name:</strong> {cert.name}
                                                                    </h6>
                                                                    <h6 className="heading-small text-muted">
                                                                        <strong>Uploaded:</strong> {this.props.ArrangeDate(cert.dateUploaded, false)}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        ) : "";
                                                    })
                                                }

                                            </Row>
                                        </Row>
                                        <hr className="my-4" />

                                    </Form>
                                    : ""
                            }


                        </CardBody>
                    </Card>
                </Col>

            </>
        );
    };
};

const mapStateToProps = (state) => ({
    Profile: state.Profile,
    pValues: state.Profile.values
})

export default connect(mapStateToProps, {
    SetValue,
    ArrangeDate,
    GetDate,
    UpdateReferences,
    GetProfileFile,
    SaveProfileFile,
    AddCertificates,
    RemoveCertificate,
})(Form_12_upload_files);
