import Index from "views/Index.js";
import Profile from "views/Profile";
import Login from "views/Login";
import ForgotPassword from "views/ForgotPassword";
import Register from "views/Register";
import JobPost from "views/JobPost";
import Notification from "views/Notification";
import PageNotFound404 from "views/PageNotFound404";

import Maps from "views/examples/Maps.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    display: true,
  },
  {
    path: "/my-profile/:type",
    name: "My Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/job-post",
    name: "Job Post",
    icon: "ni ni-single-02 text-yellow",
    component: JobPost,
    layout: "/admin",
    display: true,
  },
  {
    path: "/notifications",
    name: "Job Post",
    icon: "ni ni-single-02 text-yellow",
    component: Notification,
    layout: "/admin",
  },
  {
    path: "/404",
    name: "PageNotFound404",
    icon: "ni ni-bullet-list-67 text-red",
    component: PageNotFound404,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    icon: "ni ni-key-25 text-info",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
];
export default routes;
