import { SERVER_API, JWT } from '../../../config';
import {
    TOGGLE_ALERT,
} from 'store/types';
import axios from 'axios';
import emailjs from '@emailjs/browser';


export const ResetPassword = (email, cb = () => { } /* Succes Callback */, cb2 = () => { } /* status Callback */, cb3 = () => { } /* resetting status Callback */) => (dispatch) => {
    var generatePassword = ((
        length = 10,
        wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
    ) =>
        Array.from(crypto.getRandomValues(new Uint32Array(length)))
            .map((x) => wishlist[x % wishlist.length])
            .join(''))();

    const errorFunction = (msg) => {
        cb2("Verifying");
        cb3(false);
        dispatch({
            type: TOGGLE_ALERT,
            resType: "failed",
            msg: msg
        })
    }

    return new Promise((resolve, reject) => {
        console.log(email);
        axios({
            url: `${SERVER_API}/user/check-email`,
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
            params: {
                email: email,
            }
        })
            .then((res) => {
                console.log(res);
                if (res.data.success) {
                    if (res.data.emailExists) {
                        cb2("Sending email");
                        emailjs.send('service_l912ysg', 'template_ahe52aq', {
                            to_name: (res.data.name.first + " " + res.data.name.last).toUpperCase(),
                            new_password: generatePassword,
                            email_to: email
                        }, "99Z5vKQWgU-eoToaq")
                            .then((response) => {
                                console.log('SUCCESS!', response.status, response.text);

                                cb2("Updating Password");
                                axios({
                                    url: `${SERVER_API}/user/reset-password`,
                                    method: 'POST',
                                    headers: {
                                        'content-type': 'application/json',
                                    },
                                    data: {
                                        email: email,
                                        id: res.data.id,
                                        newPassword: generatePassword,
                                    }
                                })
                                    .then((updateRes) => {
                                        if (updateRes.data.success) {
                                            cb(true);
                                        } else {
                                            errorFunction("Failed to Update Password");
                                        }
                                    })
                                    .catch(err => {
                                        errorFunction("Failed to Update Password");
                                    })

                            }, function (error) {
                                console.log('FAILED...', error);
                                errorFunction("Failed to Send Email");
                            });


                    } else {
                        errorFunction("No Account Registered w/ this Email");
                    }
                } else {
                    errorFunction("No Account Registered w/ this Email");
                }
            })
            .catch(err => {
                errorFunction("Failed to Verify Email Account");
            })
    })
}
