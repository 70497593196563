import React, { Fragment } from "react";
import { NavLink as NavLinkRRD, Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";


class Notification extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

    }

  }


  render() {


    return (
      <>
        <Container fluid className={"notifications-container container-fluid jp-container pt-6"}>
          <div className="posting-area">
            <Row className="main-area shadow">

              <h3 className="mb-0">Notifications</h3>

              {/* <Col className="notification" md="12">
                <div className="notif-div">
                  <div className="notif-status"></div>
                  <div className="notif-img-div" >
                    <div className="notif-img">

                    </div>
                  </div>
                  <div className="notif-content-div" >
                    <div className="notif-content">
                      Job Applied New Status
                    </div>
                    <span className="notif-date">
                      3 Hours ago
                    </span>
                  </div>
                </div>
              </Col>

              <Col className="notification" md="12">
                <div className="notif-div">
                  <div className="notif-status"></div>
                  <div className="notif-img-div" >
                    <div className="notif-img">

                    </div>
                  </div>
                  <div className="notif-content-div" >
                    <div className="notif-content">
                      Job Applied New Status
                    </div>
                    <span className="notif-date">
                      5 Hours ago
                    </span>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        </Container>
      </>
    );
  };
}

const mapStateToProps = (state) => ({

})

export default withRouter(connect(mapStateToProps, {

})(Notification));