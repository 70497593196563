import { SERVER_API, JWT } from '../../../config';
import {
    TOGGLE_ALERT,
} from 'store/types';
import axios from 'axios';

export const LoginAccount = (info) => (dispatch) => {
    return new Promise((resolve, reject) => {
        console.log(info);
        axios({
            url: `${SERVER_API}/user/login-applicant`,
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: {
                email: info.username,
                password: info.password,
            }
        })
            .then((res) => {
                if (res.data.token) {
                    localStorage.setItem("nro13a-tkn", res.data.token);
                    localStorage.setItem("nro13a-info", JSON.stringify(res.data.info));
                    resolve(true);
                }
            })
            .catch(err => {
                console.log("AAAAAAAAAAAAAA");
                dispatch({
                    type: TOGGLE_ALERT,
                    resType: "failed",
                    msg: "Login Failed"
                })
                // this.props.ToggleAlert("failed", 'Login Failed', true);
            })
    })
}
