import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col,
} from "reactstrap";
import DataTable from "components/Helpers/DataTable.js";
import InfoModal from "components/Helpers/InfoModal.js";

import { GetList, ArrangeDate, ArrangeAmount, } from 'store/actions/helpers/displayAction';
import JobPostForm from './JobPostForm';
import { SET_JOB_POSTS_MODAL, SET_SEARCHED_JOB_POSTS_MODAL } from "../redux/types";

import { SetJobPostDetail } from "../redux/actions";
import { isDate } from "moment";
import { SERVER_URI } from 'config';

class JobPostStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
        }

        this.isJobApplied = this.isJobApplied.bind(this);
        this.isJobSaved = this.isJobSaved.bind(this);
        this.toggle = this.toggle.bind(this);

        const { savedJobs, appliedJobs } = props.Profile.values;
        const type = props.modalType.toLowerCase();
        var ids = [];
        var cond = { "status": "posted" }

        if (type == "applied") {
            for (let x = 0; x < appliedJobs.length; x++) {
                ids.push(appliedJobs[x].job);
            }
            cond = { _id: ids };
        } else if (type == "saved") {
            for (let x = 0; x < savedJobs.length; x++) {
                ids.push(savedJobs[x].job);
            }
            cond = { _id: ids };
        }

        props.GetList("job-post/get", SET_JOB_POSTS_MODAL, 1, 10, cond, { "date.post": -1 }, undefined, (res) => {
            console.log(res);
            if (res.data.data.jobPost.length > 0) {
                this.props.SetJobPostDetail(res.data.data.jobPost[0]._id, res.data.data.jobPost);
            }
        });

    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        })
    }

    isJobApplied(jobID) {
        var appliedJobs = this.props.Profile.values.appliedJobs;

        for (let x = 0; x < appliedJobs.length; x++) {
            if (jobID == appliedJobs[x].job) {
                return true;
            }
        }

        return false;
    }

    isJobSaved(jobID) {
        var savedJobs = this.props.Profile.values.savedJobs;

        for (let x = 0; x < savedJobs.length; x++) {
            if (jobID == savedJobs[x].job) {
                return true;
            }
        }

        return false;
    }

    render() {

        const device = localStorage.getItem("nro13a-dvctype");
        const { values } = this.props.JobPost;
        const me = this.props.Profile.values._id;
        const list = values.applicants;
        var files = [];
        console.log(list);
        console.log(me);
        for (let x = 0; x < list.length; x++) {
            if (list[x].applicant == me) {
                files = [...list[x].filesFromNEDA]
            }
        }

        console.log(files);

        return (
            <div className="row" id="job-post-status">

                {
                    (device == "desktop")
                        ? <div className="col-md-7" id="jps-details">
                            <JobPostForm />
                        </div>
                        : <InfoModal
                            size={"100%"}
                            modal={this.state.modal}
                            toggle={this.toggle}
                            title={""}
                            form={<JobPostForm />}
                            buttons={[]}
                        />
                }

                <div className={"col-md-" + ((device == "desktop") ? "5" : "12")} id="jps-list">
                    <DataTable
                        id="relevant-jobs-table"
                        title={""}
                        filter={{ status: "posted" }}
                        api={{ get: "job-post/get", search: "job-post/get" }}
                        dataBank={this.props.JobPost.modal}
                        reducers={{ get: SET_JOB_POSTS_MODAL, search: SET_SEARCHED_JOB_POSTS_MODAL }}
                        search={{
                            options: [{ value: "position", text: "Job Title" }, { value: "plantilla", text: "Plantilla" }],
                            select: [], suggest: false,
                        }}
                        table={{
                            head: () => {
                                return (
                                    <tr>
                                    </tr>
                                )
                            },
                            body: (jobPost, i) => {
                                return (
                                    <tr className={"clickable"} data-id={jobPost._id} onClick={async (e) => {
                                        this.props.SetJobPostDetail(jobPost._id, this.props.JobPost.modal.toDisplay);
                                        if (device == "mobile") {
                                            this.toggle();
                                        }
                                    }}>
                                        <td scope="col">
                                            <div className="job-post">
                                                <Card className={"shadow" + ((jobPost._id == this.props.JobPost.values._id) ? " highlight" : "")}>
                                                    <CardHeader className="bg-transparent">
                                                        <span>Date Posted: <em>{this.props.ArrangeDate(jobPost.date.post, false)}</em></span>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <span>Job Title: </span><br />
                                                        <p>{jobPost.position.toUpperCase()}</p>
                                                        <p>{"SG-" + jobPost.sg.grade + " | " + this.props.ArrangeAmount(jobPost.sg.salary)} </p>
                                                    </CardBody>
                                                    <CardFooter>
                                                        <span>Deadline of Submission: <em>{this.props.ArrangeDate(jobPost.date.close, false)}</em></span>
                                                    </CardFooter>
                                                </Card>
                                            </div>

                                        </td>

                                    </tr>
                                )
                            }
                        }}
                    />
                </div>

                <Row id="notices-div" className="ml-2 mt-5">
                    <Col xs="12">
                        <h3 className="mb-0">Notice File/s</h3>
                    </Col>

                    {
                        files.map((file, i) => {
                            return (
                                <Col key={"jpf-" + i} className="notice-file ml-3 mt-3" xs="8"
                                    onClick={() => {
                                        window.open(SERVER_URI + "files/notice/" + file.path);
                                    }}
                                >
                                    {file.name.toUpperCase()}
                                </Col>
                            )
                        })
                    }
                </Row>

            </div>
        );
    };
};

const mapStateToProps = (state) => ({
    JobPost: state.JobPost,
    Profile: state.Profile,
})

export default connect(mapStateToProps, {
    GetList,
    ArrangeDate,
    ArrangeAmount,
    SetJobPostDetail,
})(JobPostStatus);
