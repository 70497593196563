import React from "react";
import { NavLink as NavLinkRRD, Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { FaCircleNotch } from 'react-icons/fa';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
} from "reactstrap";

import { ResetPassword } from "./redux/actions";


class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            resetting: false,

            resetStatusMessage: "Verifying",

            email: "",

            codeSent: false,

            timerStart: false,
            time: 10

        }

        this.Timer = this.Timer.bind(this);

    }

    Timer() {
        var timer = setInterval(() => {

            this.setState({
                time: this.state.time - 1
            })

            if ((this.state.time - 1) < 0) {
                clearInterval(timer);
            }

        }, 1000);
    }

    render() {
        console.log(this.state.time)
        return (
            <>
                <Col id="forgot-password" lg="5" md="7">
                    <Card className="bg-secondary shadow border-0">

                        <Link
                            className="h4 pt-4 pl-4 d-lg-inline-block"
                            style={{ cursor: "pointer" }}
                            to="/auth/login"
                        >
                            <i className="ni ni-bold-left" /> Sign in
                        </Link>

                        <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                                <small>Forgot Password</small>
                            </div>
                            <Form role="form">
                                {
                                    (!this.state.codeSent)
                                        ? <>
                                            <FormGroup className="mb-3">
                                                <label>Email</label>
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-email-83" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder=""
                                                        type="email"
                                                        autoComplete="new-email"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                email: e.target.value
                                                            })
                                                        }}
                                                        onKeyUp={(e) => {
                                                            if (e.code == "Enter" && this.state.email != "") {

                                                            }
                                                        }}
                                                    />
                                                </InputGroup>
                                            </FormGroup>

                                            <div className="text-center mb-4">
                                                <Button className="mt-4" color="primary" type="button" disabled={!(this.state.email != "" && !this.state.resetting)} onClick={() => {

                                                    this.setState({
                                                        resetting: true
                                                    })

                                                    this.props.ResetPassword(
                                                        this.state.email,
                                                        (bool) => {
                                                            this.setState({
                                                                codeSent: bool
                                                            });
                                                        },
                                                        (message) => {
                                                            this.setState({
                                                                resetStatusMessage: message
                                                            });
                                                        },
                                                        (bool) => {
                                                            this.setState({
                                                                resetting: bool
                                                            });
                                                        }
                                                    );
                                                }}>
                                                    {
                                                        !this.state.resetting
                                                            ? "Reset Password"
                                                            : <><span style={{ fontSize: "18px" }}> <FaCircleNotch className="rotate" id="file-loading-icon" />  </span> {this.state.resetStatusMessage}...</>
                                                    }
                                                </Button><br />

                                            </div>
                                        </>
                                        : <>
                                            {/* <FormGroup className="mt-5 mb-1" style={{ display: "flex", justifyContent: "center" }}>
                                                <div style={{ width: "70%", textAlign: "center" }}>
                                                    <label>Verification Code</label>
                                                    <InputGroup className="input-group-alternative">

                                                        <Input
                                                            className="verif-code"
                                                            style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", textDecoration: "none" }}
                                                            placeholder=""
                                                            type="number"
                                                            min="0"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    email: e.target.value
                                                                })
                                                            }}
                                                            onKeyUp={(e) => {
                                                                if (e.code == "Enter" && this.state.password != "" && this.state.password.length > 5 && this.state.email != "") {

                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </div>
                                            </FormGroup>
                                            <div className="text-center mb-4">
                                                <Button className="mt-4" color="primary" type="button" onClick={() => {
                                                    this.props.history.push("/auth/login");
                                                    window.location.reload();
                                                }}>
                                                    Verify
                                                </Button><br />

                                            </div> */}
                                            <Col lg="12" md="12" id="reset-success">
                                                <Card className="border-0">
                                                    <CardBody className="px-lg-5 py-lg-5">
                                                        <span>Your new Password will be sent to you Email!</span>
                                                    </CardBody>

                                                    <CardFooter>
                                                        <Link
                                                            className="h5 mb-0 text-light d-lg-inline-block"
                                                            to="/auth/login"
                                                        >
                                                            Login
                                                        </Link>
                                                    </CardFooter>
                                                </Card>
                                            </Col>
                                        </>
                                }




                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </>
        );
    };
}

const mapStateToProps = (state) => ({
})

export default withRouter(connect(mapStateToProps, {
    ResetPassword,
})(ForgotPassword));